@import "_reboot.scss";

// variables
@import "_variables.scss";
@import "_colors.scss";

// utilities
@import "_breakpoints.scss";

// shared styles
@import "_mixins.scss";
@import "_buttons.scss";

// layout
@import "_layout.scss";
@import "_builder.scss";
@import "_page_header.scss";
@import "_page_title.scss";
@import "_footer.scss";

// typography
@import "_text.scss";

// components
@import "_actions.scss";
@import "_cards.scss";
@import "_forms.scss";
@import "_table.scss";
@import "_progress_bars.scss";
@import "_step_list.scss";

// navigation
@import "_nav.scss";

// app sections
@import "public.scss";
@import "app.scss";
@import "admin.scss";
@import "install.scss";

// utilities
@import "_paginator.scss";

body, html {
    background-color: #DBE7F0;
}