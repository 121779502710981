.card-grid {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .card {
        width: 100%;
        box-sizing: border-box;
        padding: 2rem;
        background: white;
        border: 2px solid $optimus-blue-20;
        border-radius: $cornerRadius;
        text-decoration: none !important;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &:hover {
            border-color: $optimus-blue;
        }
        .title {
            color: $dark-grey;
            font-size: 1.6rem;
            font-weight: 700;
        }
        .status-block {
            margin-bottom: 2rem;
            .status {
                display: block;
                text-align: center;
                font-size: 1.4rem;
                font-weight: 500;
                padding: 1rem 0;
            }
        }
        .progress-bars {
            .progress-bar {
                background-color: $optimus-blue-20;
            }
        }
        .progress-label {
            text-align: center;
            color: $medium-grey;
            font-size: 1.2rem;
            font-weight: 500;
        }
        &.add-item {
            background-color: $optimus-blue-20-hex;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            border-color: $optimus-blue-20-hex;
            .title {
                color: $optimus-blue;
            }
            &:hover, &:focus {
                background-color: $optimus-blue;
                .title {
                    color: white;
                }
            }
        }
    }
}