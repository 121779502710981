@import "_colors";
@import "_mixins";

.modal {
    .modal-dialog {
        @include shadow;
        background: white;
        position: fixed;
        top: 5vh;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 5000;
        max-width: 90vw;
        max-height: 90vh;
        min-width: 50vw;
        min-height: 30vh;
        .modal-content {
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            height: auto;
            width: auto;
            padding: 4rem;
            position: relative;
            z-index: 200;
            .close-icon {
                position: relative;
                top: -2rem;
                left: 100%;
                z-index: 100;
                font-size: 2rem;
                &:hover {
                    cursor: pointer;
                    color: $optimus-blue;
                }
            }
        }
    }
    .modal-backdrop {
        opacity: .1;
        z-index: 1;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: black;
    }
}
    