.action-group {
    background-color: $optimus-blue-y;
    padding: 2rem;
    color: $optimus-blue;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: $cornerRadius;
    display: block;
    margin: 1rem auto;
    .action-header {
        font-weight: 600;
    }
}