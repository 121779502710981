.installation-wrapper {
    padding: 1rem;
    box-sizing: border-box;
    font-size: 1.5rem;
    .template-group-tile {
        border: 2px solid $white;
        border-radius: 4px;
        background-color: white;
        padding: 1.5rem;
        margin-bottom: 1rem;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, .15);
        .title-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title {
                font-weight: 600;
                color: $optimus-blue;
            }
            .icon {
                color: $optimus-blue-50;
            }
            margin-bottom: .7rem;
        }
        &:hover, &:focus {
            cursor: pointer;
            // background-color: $optimus-blue-20;
            border: 2px solid #0A6AA9;
        }
        .answer-progress {
            height: 4px;
            width: 100%;
            border-radius: 2px;
            background-color: $light-grey;
            position: relative;
            margin-bottom: .7rem;
            .progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                border-radius: 2px;
                background-color: $optimus-blue;
            }
        }
        .progress-status {
            font-size: 1rem;
            color: #999;
            margin-top: .5rem;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .input-wrapper {
        margin-bottom: .3rem;
        .form-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $dark-grey;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: .5rem;
            .label-text {
                display: flex;
                align-items: center;
            }
            .info-icon {
                margin-left: 1rem;
                svg {
                    color: $dark-grey;
                    font-size: 1.6rem;
                }
                &:hover, &:focus {
                    cursor: pointer;
                    svg {
                        color: $optimus-blue;
                    }
                }
            }
            .status-icon {
                margin-right: 2rem;
                svg {
                    font-size: 2rem;
                }
            }
        }
        .instructions {
            // color: $optimus-red;
            p {
                font-size: 1.4rem;
                font-weight: 500;
                margin-top: .2rem;
            }
        }
    }
    .input-content {
        background-color: white;
        // padding: 1rem;
        // border: 2px solid $optimus-blue-20;
        margin-bottom: 1rem;
        border-radius: 4px;
        p {
            margin-top: 0;
        }
    }
    .dashboard-wrapper {
        // &.is-large { columns or not columns }
        // &.active {
        .question-group {
            background-color: white;
            padding: 2rem;
            border-radius: 4px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, .15);
        }
        &.admin-box {
            background-color: white;
            padding: 3rem 4rem;
            border-radius: 2rem;
            .qGroup {
                border: 1px solid $optimus-blue;
                padding: 2rem;
                margin-bottom: 1rem;
                h3 {
                    &:hover, &:focus {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                .answer-review {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    border-bottom: 1px solid $optimus-blue-20;
                    padding-bottom: 1rem;
                    .question {
                        color: $dark-grey;
                        font-size: 1.4rem;
                    }
                    .answer {
                        font-size: 1.8rem;
                    }
                    .actions {
                        font-size: 3rem;
                        svg, i {
                            margin-left: 1rem;
                            color: $light-grey;
                            &:hover, &:focus {
                                cursor: pointer;
                                color: $dark-grey;
                            }
                        }
                        .approve {
                            color: $optimus-blue;
                            &:hover, &:focus {
                                cursor: pointer;
                                color: $dark-grey;
                            }
                        }
                        .reject {
                            color: $optimus-red;
                            &:hover, &:focus {
                                cursor: pointer;
                                color: $dark-grey;
                            }
                        }
                    }
                }
            }

        }
        .installation-render-wrapper {
            display: flex;
            gap: 3rem;
            .render-template-groups {
                flex: 1 1 auto;
            }
            .render-group-questions {
                flex-basis: 50%;
                display: none;
                &.active {
                    display: block;
                    &.mobile-show {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}

.image-examples {
    margin-bottom: 2rem;
    .photos {
        display: flex;
        flex-direction: row;
        .example-photo {
            margin-right: 1rem;
        }
    }
}

.image-wrapper {
    margin-top: -1rem;
    margin-bottom: 1rem;
    &:hover, &:focus {
        cursor: pointer;
        opacity: .8;
    }
}

.rejected-notes {
    border-left: 6px solid $optimus-red;
    padding-left: 1rem;
    padding-bottom: .5rem;
    margin-top: -1rem;
    margin-bottom: 2rem;
    .error-label {
        display: inline-block;
        margin-bottom: .5rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: .05rem;
        color: $optimus-red;
        font-weight: 500;
    }
    p {
        margin: 0;
        font-weight: 500;
    }
}