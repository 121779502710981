a.btn {
    text-decoration: none;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    color: #333;
    border: 1px solid $black;
    padding: 1.5rem 1rem;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    border-radius: $cornerRadius;
    transition: .2s all ease;
    box-sizing: border-box;
    &:hover, &:focus {
        cursor: pointer;
        background-color: $dark-grey;
        color: white;
    }
    &.small {
        padding: 1rem 0;
        font-size: 1.2rem;
    }
    &.black {
        background-color: $black;
        color: white;
        &:hover, &:focus {
            background-color: $dark-grey;
        }
    }
    &.submit {
        background-color: $optimus-blue;
        border-color: $optimus-blue;
        color: white;
        border-radius: 7px;
        &:hover, &:focus {
            background-color: $white;
            color: $optimus-blue;
        }
        &.disabled {
            opacity: 0.5;
            &:hover, &:focus {
                cursor: default;
            }
        }
    }
    &.select {
        &:hover, &:focus {
            background-color: $medium-grey;
            color: $dark-grey;
        }
        &.active {
            background-color: $dark-grey;
            color: white;
        }
    }
    &.blue {
        background-color: $optimus-blue;
        border-color: $optimus-blue;
        color: white;
        &:hover, &:focus {
            background-color: $optimus-blue-20-hex;
            color: $optimus-blue;
        }
    }
    &.blue-outline {
        border-color: $optimus-blue;
        color: $optimus-blue;
        &:hover, &:focus {
            background-color: $optimus-blue;
            color: white;
        }
    }
    &.red {
        background-color: $optimus-red;
        border-color: $optimus-red;
        color: white;
        &:hover, &:focus {
            background-color: $optimus-red-20;
            color: $optimus-red;
        }
    }
    &.green {
        margin-bottom: 1rem;
        background-color: $optimus-green;
        border-color: $optimus-green;
        color: white;
        &:hover, &:focus {
            background-color: $light-grey;
            color: $optimus-green;
        }
    }
}