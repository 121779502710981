.footer-btns {
    position: fixed;
    background-color: $optimus-blue-20-hex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7.5rem;
    padding: 1rem;
    box-sizing: border-box;
    .btn {
        &.main {
            // border-top-right-radius: 0;
            // border-bottom-right-radius: 0;
            // width: 100%;
            // box-sizing: border-box;
        }
        &.action {
            margin-left: 1rem;
        }
    }
}