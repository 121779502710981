.nav {
    .nav-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        align-items: center;
        padding: 1rem;
        // border-bottom: thin solid $optimus-blue-20;
        .menu-wrapper {
            // flex: 0 0 3rem;
            margin-left: 1rem;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            color: $dark-grey;
            z-index: 10;
            &:hover {
                cursor: pointer;
                color: $optimus-blue;
            }
        }
        .nav-title {
            font-size: 1.6rem;
            font-weight: 600;
            color: $optimus-blue;
            span, a {
                display: flex;
                align-items: center;
                color: $optimus-blue;
                text-decoration: none;
                i, svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    padding: .5rem;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: $optimus-blue-20;
                    margin-right: .5rem;
                    margin-top: -.125rem;
                }
            }
        }
    }
    @include respond-to-min('tablet') {
        position: absolute;
        right: 1rem;
        top: 1rem;
        .nav-wrapper {
            padding: 0;
            .nav-title {
                display: none;
            }
        }
    }
}

.menu-overlay {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100%;
    height: 100vh;
    background-color: $optimus-blue;
    transition: .5s;
    z-index: 20;
    @include respond-to-min('mobile') {
        max-width: 400px;
        right: -400px;
    }
    &.active {
        right: 0vw;
        @include respond-to-min('mobile') {
            right: 0px;
        }
    }
    .closer {
        position: absolute;
        top: 1rem;
        left: 1rem;
        font-size: 2.5rem;
        color: white;
        &:hover {
            cursor: pointer;
            color: $medium-grey;
        }
    }
    .menu {
        height: calc(100vh - 6rem);
        width: 100%;
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .links {
            display: flex;
            flex-direction: column;
            a {
                color: white;
                font-size: 2.5rem;
                padding: 1.5rem 1rem;
                text-decoration: none;
                display: list-item;
                list-style-type: circle;
                list-style-position: inside;
                font-weight: 500;
                &::marker {
                    color: transparent;
                }
                &:hover {
                    &::marker {
                        color: white;
                    }
                }
            }
        }
        .logo-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 2rem);
        }
    }
}