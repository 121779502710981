/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $screen-breakpoints
@mixin respond-to-max($breakpoint) {
  @if map-has-key($screen-breakpoints, $breakpoint) {
    @media (max-width: map-get($screen-breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($screen-breakpoints)}.";
  }
}

@mixin respond-to-min($breakpoint) {
  @if map-has-key($screen-breakpoints, $breakpoint) {
    @media (min-width: map-get($screen-breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($screen-breakpoints)}.";
  }
}
