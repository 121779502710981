.builder-view {
    width: 100%;
    .manual-header {
        display: flex;
        flex-direction: row;
        width: calc(100% - 3rem);
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $optimus-blue;
        height: calc(6rem - 1px);
        background-color: $optimus-blue;
        // margin-right: 5rem;
        // padding-right: 10rem;
        padding: 0 2rem 0 1rem;
        h1 {
            font-size: 2rem;
            margin-top: 0;
            margin-bottom: 0;
            // padding-top: 1rem;
            // padding-bottom: 1rem;
            color: white;
            border-bottom: 1px dashed white;
        }
        .manual-header-actions {
            display: flex;
            margin-right: 2rem;
            .btn {
                font-size: 1.4rem;
                padding: 1rem;
                background-color: white;
                margin-left: 1rem;
                &:hover {
                    color: $optimus-blue;
                }
            }
        }
    }
    .manual-wrapper {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 6rem);
        background-color: $optimus-blue-10;
        .builder-sidebar {
            border-right: thin solid $optimus-blue;
            padding: 1rem 1rem 1rem 0;
            width: 30rem;
            flex-basis: 30rem;
    
            .group-wrapper {
                // margin-bottom: .5rem;
                padding: 1rem .5rem;
                width: 100%;
                // border-radius: $cornerRadius;
                border-bottom: 1px solid $optimus-blue;
                // background-color: $optimus-blue-10;
                .group-header {
                    line-height: 1.5;
                    font-weight: 400;
                    font-size: 1.2rem;
                    padding-left: .2rem;
                    margin-bottom: 1rem;
                    border-bottom: 1px dashed $optimus-blue;
                    color: $optimus-blue;
                }
                .group-label {
                    p {
                        font-size: 1.2rem;
                        text-transform: uppercase;
                        font-weight: 400;
                        color: $optimus-blue;
                        border-bottom: 2px solid $optimus-blue;
                        padding-bottom: .5rem;
                        margin-top: 0;
                    }
    
                    border-bottom: none;
                    margin-bottom: .5rem;
                }
    
                .btn.dashed {
                    border-style: dashed;
                    opacity: .5;
                }
            }
            .add-group {
                width: calc(100% - 1rem);
                background-color: $optimus-blue-10;
                color: $optimus-blue;
                padding: 1rem;
                text-align: center;
                font-size: 1.5rem;
                font-weight: 400;
                &:hover {
                    background-color: $optimus-blue;
                    color: white;
                    cursor: pointer;
                }
            }
    
            &.builder-steps{
                .step-item {
                    padding: 1rem;
                    background-color: white;
                    color: $optimus-blue;
                    font-weight: 600;
                    margin: .5rem 0;
                    border-radius: $cornerRadius;
                    display: block;
                    text-decoration: none;
    
                    &.active {
                        background-color: $optimus-blue;
    
                        .step-label {
                            color: white !important;
                        }
                    }
    
                    .step-label {
                        color: $optimus-blue;
                    }
                }
            }
        }
        .builder-content {
            display: flex;
            flex-direction: column;
            // TODO: Calc this for real....
            width: calc(100% - 30rem);
            .builder-step-header {
                width: 100%;
                height: 3.5rem;
                h2 {
                    padding: .25rem;
                    padding-left: 1rem;
                    line-height: 1.5;
                    margin: 0;
                }
            }
            .content-wrapper {
                display: flex;
                flex-direction: row;
                border-top: 1px solid $optimus-blue;
                height: calc(100vh - 9.5rem);
                .builder-page-content {
                    width: calc(100% - 30rem);
                    background: white;
                    padding: 1rem;
                    h3 {
                        margin: 0;
                        line-height: 1.5;
                    }
                    .content-block {
                        position: relative;
                        padding: 1rem;
                        padding-right: 3rem;
                        border: 1px solid transparent;
                        margin-bottom: 1rem;
                        font-size: 1.6rem;
                        &.previewing {
                            border: 1px dashed #DEDEDE;
                        }
                        // &.editing {
                        //     border: 1px solid $optimus-blue;
                        // }
                        .content-actions {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            div {
                                display: flex;
                                flex: 1 1 33.33%;
                                align-items: center;
                                justify-content: center;
                                &:hover {
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }
                        }
                        .delete-btn {
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            color: $optimus-blue;
                        }
                    }
                }
                .builder-sidebar {
                    border-right: thin solid $optimus-blue;
                    padding: 1rem 1rem 1rem 0;
                    width: 30rem;
                    flex-basis: 30rem;
            
                    &.builder-substeps {
                        .step-item {
                            padding: 1rem;
                            background-color: white;
                            color: $optimus-blue;
                            font-weight: 600;
                            margin: .5rem 0;
                            border-radius: $cornerRadius;
                            display: block;
                            text-decoration: none;
            
                            &.active {
                                background-color: $optimus-blue;
            
                                .step-label {
                                    color: white !important;
                                }
                            }
            
                            .step-label {
                                color: $optimus-blue;
                            }
                        }
                    }
            
                    &.builder-substeps {
                        padding: 0 1rem;
                    }
                }
            }

        }
        
        .inline-edit {
            border-bottom: thin dashed $dark-grey;
            p {
                margin-bottom: 0;
            }
            .form-group {
                display: flex;
                .input-wrapper {
                    flex: 1 1 auto;
                    margin-bottom: 0;
                    input {
                        font-size: 1.5rem;
                        font-weight: 400;
                        // border-top-right-radius: 0;
                        // border-bottom-right-radius: 0;
                    }
                }
                .form-btn-wrapper {
                    display: flex;
                    padding-bottom: 1rem;
                    margin-left: 1rem;
                    .btn {
                        font-size: 1.5rem;
                        align-self: center;
                        padding: 1.1rem 1rem;
                        // border-radius: 0;
                        margin-left: .5rem;
                        &:last-child {
                            margin-left: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.with-delete-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .delete-btn {
        color: $optimus-blue;
        font-size: 1.5rem;
        margin-right: 1rem;
        &:hover {
            cursor: pointer;
        }
    }
    margin-bottom: 1rem;
}
