form {
    .invalid-feedback {
        visibility: hidden;
    }

    &.was-validated {
        .invalid-feedback,
        .helper-text {
            visibility: visible;
            padding-top: .5rem;
            font-size: 1.3rem;
            position: relative;
            color: $optimus-red;
            &.invisible {
                visibility: hidden;
            }
        }
    }
    .error {
        color: $optimus-red;
        font-size: 1.3rem;
        display: block;
        margin: 1rem 0;
        text-align: center;
    }
}
.input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;
    label {
        display: flex;
        color: $dark-grey;
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
    input, select {
        border: 2px solid $optimus-blue;
        border-radius: 7px;
        font-size: 2.25rem;
        font-weight: 500;
        padding: .5rem 1.5rem;
        box-sizing: border-box;
        width: 100%;
    }
    ::placeholder {
        font-weight: 300;
        font-size: 2rem;
        font-style: italic;
    }
    .helper-text.invisible {
        visibility: hidden;
    }
}

.d-none {
    display: none;
}