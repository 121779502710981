// #app {
    // .content {
    //     display: flex;
    //     flex-direction: column;
    //     min-height: 100vh;
    //     position: absolute;
    //     padding: 0 1.5rem;
    //     width: calc(100% - 3rem);    
    // }

#app {
    // display: grid;
    // grid-template-rows: min-content 1fr;
    // grid-template-columns: 1fr;
    height: 100vh;
    // padding-top: 4rem;
    // max-width: 992px;
    margin: 0 auto;
    .nav {
        grid-row: 1;
        grid-column: 1;
    }
    .content-wrapper {
        grid-row: 2;
        grid-column: 1;
        position: relative;
    }
    .step-wrapper {
        height: 4px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        .step {
            position: relative;
            margin-right: .4rem;
            background-color: $light-grey;
            height: 4px;
            border-radius: 2px;
            .progress {
                position: relative;
                top: 0;
                left: 0;
                background-color: $optimus-blue;
                height: 4px;
                border-radius: 2px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .profile-wrapper {
        width: 100%;
        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 2rem 10rem;
            h2 {
                font-size: 2.5rem;
                font-weight: 600;
            }
            h3 {
                font-size: 2rem;
                font-weight: 600;
                line-height: 0;
            }
        }
        .label-values {
            font-size: 1.5rem;
            .values {
                color: $optimus-blue;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        form {
            width: 100%;
            .form-group {
                .input-wrapper {
                    margin-bottom: 1rem;
                    .form-label {
                        font-size: 1.5rem;
                    }
                    input {
                        font-size: 1.6rem !important;
                    }
                }
                .form-btn-wrapper {
                    display: flex;
                    justify-content: space-evenly;
                    margin-bottom: 2rem;
                    .btn {
                        width: 100px;
                        font-size: 1.5rem;
                    }
                    &.cancel {
                        margin-left: 1rem;
                    }
                }
            }
        }
        .manage-auth {
            display: flex;
            margin: 4rem auto 0 auto;
            .btn.grey{
                padding: 1.5rem 0;
                width: 30rem;
            }
        }
    }

    .team-wrapper {
        max-width: 80rem;
        width: 100%;
        text-align: center;
        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 2rem 10rem;
            h2 {
                font-size: 2.5rem;
                font-weight: 600;
            }
            h3 {
                font-size: 2rem;
                font-weight: 600;
                line-height: 0;
            }
        }
        .pagination-wrapper {
            .pagination-controls {
                padding: 1rem 0;
            }
            .table.striped {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.content-box {
    background-color: white;
    padding: 1.25rem 2rem 2rem 2rem;
    margin: 1rem 0;
    border-radius: 1rem;
}

.clickable {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}