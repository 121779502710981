$breakpoints: (
  xs: 350px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$screen-breakpoints: (
    'mobile': map-get($breakpoints, 'sm'),
    'tablet': map-get($breakpoints, 'md'),
    'laptop': map-get($breakpoints, 'lg'),
    'desktop': map-get($breakpoints, 'xl'),
    'large': map-get($breakpoints, 'xxl')
) !default;

$cornerRadius: .7rem;
$sidebarWidth: 30rem;
// $sidebarGutter: 5rem;
