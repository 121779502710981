@import url('//fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,300&display=swap');

/* Base text styling */
body, html {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  line-height: 2rem;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-weight: 400;
  color: $optimus-blue;
  margin-top: 0;
}

h1, .h1 {
  font-size: 3.2rem;
  line-height: 1.2;
  margin-bottom: .25rem;
}

h2, .h2 {
  font-size: 2rem;
  line-height: 1.21875;
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.25;
}

h4, .h4 {
  font-size: 1.5rem;
}

p, a, ul, li {
  font-size: 1.6rem;
}

b, strong {
  font-weight: 800;
}

a {
  color: $optimus-blue;
}