.table {
    width: 100%;
    -webkit-border-horizontal-spacing: 0;
    td, th {
        font-size: 1.5rem;
        padding: .5rem;
    }
    td {
        border-top: 1px solid $optimus-blue;
        border-left: 1px solid $optimus-blue;
        &:last-child {
            border-right: 1px solid $optimus-blue;
        }
        // border-right: 1px solid $optimus-blue;
    }
    tr {
        &:last-child {
            td {
                border-bottom: 1px solid $optimus-blue;
                // border-right: 1px solid $optimus-blue;
            }
        }
        
    }
}