#public {
    .login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 80vh;
        padding: 0rem 3rem;
        margin-top: 4rem;
        form, p {
            margin: 5rem 0 0 0;
            .btn.submit {
                width: 50%;
                margin: 0 auto;
            }
            .additional-links {
                line-height: 1.4;
            }
        }
        .additional-links {
            text-align: center;
            margin-bottom: 4rem;
            font-size: 1.5rem;
            font-weight: 600;
            color: $optimus-blue;
            a {
                color: $optimus-blue;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            p {
                margin-bottom: 4rem;
            }
        }
        .validation-errors {
            color: red;
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
            margin-bottom: 2rem;
            p {
                margin: 0;
            }
        }
        .password-reset {
            text-align: center;
            margin-top: 5rem;
            font-size: 2rem;
            font-weight: 600;
            color: $optimus-blue;
            form {
                margin-top: 5rem;
        
                .btn.submit {
                    width: 50%;
                    margin: 0 auto;
                }
        
                .form-group {
                    span {
                        margin-bottom: 5rem;
                    }
                }
            }
            .link {
                margin-top: 2rem;
                color: $optimus-blue;
                text-decoration: none;
                font-weight: 600;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}