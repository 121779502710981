.page-title-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: min-content auto;
    font-size: 1.4rem;
    color: black;
    .page-label, .page-status {
        font-weight: 600;
    }
    .page-label {
        grid-row: 1;
        grid-column: 1 / span 1;
        padding-right: 1rem;
        white-space: nowrap;
    }
    .page-status {
        grid-row: 1;
        grid-column: 2 / span 1;
    }
    .page-title {
        grid-row: 2;
        grid-column: 1 / span 2;
        margin: .5rem 0 0 0;
        color: black;
        font-weight: 800;
        font-size: 3.2rem;
    }
    @include respond-to-max('tablet') {
        padding-top: 0;
    }
}