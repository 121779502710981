.step-list-wrapper {
    position: relative;
    .step-list-header {
        border-bottom: thin solid $optimus-blue;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        .step-title {
            font-size: 2.4rem;
            font-weight: 600;
            color: $optimus-blue;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
        .step-subtitle {
            font-size: 1.4rem;
            font-weight: 600;
            color: $optimus-blue;
            margin-top: -1.5rem;
            margin-bottom: 1rem;
        }
    }
    .step-list {
        .group-wrapper {
            background-color: $optimus-blue-10;
            border: thin solid $optimus-blue;
            padding: 1rem .5rem;
            border-radius: $cornerRadius;
            margin-bottom: 2rem;
            .group-label {
                font-size: 1.2rem;
                font-weight: 400;
                color: $optimus-blue;
                // margin-top: 1rem;
                margin-bottom: 1rem;
            }
            .btn {
                border-style: dashed;
                opacity: .5;
                &:hover, &:focus {
                    opacity: 1;
                    border-style: solid;
                }
            }
        }
        .btn {
            margin-top: 1rem;
        }
        .step-item {
            background: white;
            border-radius: $cornerRadius;
            border: 2px solid transparent;
            margin-bottom: 1rem;
            overflow: hidden;
            &.active {
                background-color: $optimus-blue-10;
                border-color: white;
            }
            .step-details {
                display: flex;
                flex-wrap: nowrap;
                padding: 1rem 2rem;
                font-size: 1.4rem;
                .step-count {
                    flex: 0 0 6rem;
                    font-weight: 600;
                }
                .step-label {
                    flex: 1 1 auto;
                }
                .step-status {
                    flex: 0 0 2rem;
                    font-size: 2rem;
                }
            }
            .step-action {
                & > div {
                    position: relative;
                    padding: .75rem 2rem;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: $optimus-blue;
                    position: relative;
                    font-size: 1.4em;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 5%;
                        width: 90%;
                        height: 1px;
                        background-color: $optimus-blue-20;
                        z-index: 1;
                    }
                    i, svg {
                        font-size: 1.5rem;
                        margin-right: .5rem;
                    }
                    &.error {
                        background-color: $optimus-red;
                        color: white;
                    }
                }
            }
        }
    }
}