.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
    @each $name, $value in $screen-breakpoints {
        @debug "Breakpoint: #{$name}, Value: #{$value}";
        @include respond-to-min($name) {
            max-width: $value;
        }
    }
}

.content {
    padding: 1rem;
}

#app {
   display: grid;
   grid-template-rows: min-content 1fr;
   grid-template-columns: 1fr;
   height: 100vh;
   .nav {
    grid-row: 1;
    grid-column: 1;
   }
   .content-wrapper {
    grid-row: 2;
    grid-column: 1;
    position: relative;
   }
}

.sidebar-layout {
    display: grid;
    grid-template-columns: $sidebarWidth auto;
    transition: .25s linear;
    overflow: hidden;
    .sidebar {
        background-color: $optimus-blue-20-hex;
        width: $sidebarWidth;
        position: relative;
        z-index: 2;
        left: 0;
        transition: left .25s linear;
        height: 100%;
        .sidebar-wrapper {
            padding: 1rem;
        }
        .sidebar-control {
            display: block;
            position: absolute;
            width: 3rem;
            height: 3rem;
            z-index: 10;
            text-align: center;
            font-size: 1.8rem;
            line-height: 1.7;
            &.show-sidebar {
                display: none;
                top: 6rem;
                right: -3rem;
                background-color: $optimus-blue-20-hex;
                border-top-right-radius: .35rem;
                border-bottom-right-radius: .35rem;
            }
            &.hide-sidebar {
                display: block;
                position: absolute;
                top: 2rem;
                right: 0;
                background-color: $optimus-blue-x;
                border-top-left-radius: .35rem;
                border-bottom-left-radius: .35rem;
            }
        }
    }
    .content {
        position: relative;
        z-index: 1;
    }
    &.open {
        grid-template-columns: $sidebarWidth auto;
    }
    &.closed {
        grid-template-columns: 0 auto;
        .sidebar {
            left: calc(-1 * #{$sidebarWidth});
            .show-sidebar {
                display: block;
            }
        }
    }
    @include respond-to-max('tablet') {
        grid-template-columns: 100% !important;
        .sidebar {
            position: absolute;
            width: 100vw;
        }
        .content {
            background-attachment: purple;
        }
        &.closed {
            .sidebar {
                left: -100vw;
                .show-sidebar {
                    display: none;
                }
            }
        }
        &.open {
            .sidebar {
                left: 0;
                .hide-sidebar {
                    display: none;
                }
            }
        }
    }
}

// random helpers
// .mobile-show {
//     @include respond-to-min('mobile') {
//         display: none !important;
//     }
// }

.mobile-hide {
    @include respond-to-max('mobile') {
        display: none !important;
    }
}

// helpers left over from bootstrap !!REMOVE?!!
// let's remove and try class-based styling

// .mb-1 {
//     margin-bottom: 1rem;
// }

// .mb-2 {
//     margin-bottom: 2rem;
// }