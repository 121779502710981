.progress-bars {
    display: flex;
    .progress-bar {
        position: relative;
        height: 6px;
        border-radius: 6px;
        overflow: hidden;
        background-color: white;
        margin: 1rem .25rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        .progress-fill {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: $optimus-blue;
        }
    }
}
.progress-label {
    font-size: 1.4rem;
    font-weight: 600;
    color: $optimus-blue;
    text-align: center;
}